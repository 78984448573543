import React, { useEffect, useState } from "react";
import {
  completeMultipartUpload,
  createFlight,
  getLocations,
  getMultipartUploadId,
  getPresignedUrl,
} from "../api/index";
import {
  PlusCircle,
  X,
  Upload,
  Check,
  Send,
  Clock,
  FileVideo,
} from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../components/SuccessModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import CustomeTimePicker, {
  CustomDateTimePicker,
  CustomTimePicker,
} from "../components/CustomTimePicker";

const Loader = () => (
  <div className="flex items-center justify-center">
    <svg
      className="animate-spin h-5 w-5 text-blue-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
      ></path>
    </svg>
  </div>
);

const Progress = ({ value }) => (
  <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden relative">
    <div
      className="bg-blue-600 h-2 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${value}%` }}
    ></div>
  </div>
);

// const PART_SIZE = 6 * 1024 * 1024;

const FlightForm = ({
  flight,
  index,
  districts,
  flights,
  locationData,
  setFlights,
}) => {
  const calculatePartSize = (fileSize) => {
    const MIN_PART_SIZE = 6 * 1024 * 1024; //  6 MB minimum
    const MAX_PART_SIZE = 500 * 1024 * 1024; // 100 MB maximum

    // Calculate optimal part size based on file size
    let partSize = Math.ceil(fileSize / 20); // Divide into roughly 10 parts

    // Ensure part size is within min and max constraints
    partSize = Math.max(partSize, MIN_PART_SIZE);
    partSize = Math.min(partSize, MAX_PART_SIZE);

    return partSize;
  };
  const calculateMinStartDate = () => {
    const today = new Date();
    const minStartDate = new Date(today);
    minStartDate.setDate(today.getDate() - 16);
    return minStartDate;
  };

  // Custom time filter for start time
  const filterStartTime = (time) => {
    const today = new Date();
    const currentTime = new Date();

    // If selected date is today, restrict time to before current time
    if (
      time.getDate() === today.getDate() &&
      time.getMonth() === today.getMonth() &&
      time.getFullYear() === today.getFullYear()
    ) {
      return time <= currentTime;
    }

    // For other dates, allow all times
    return true;
  };

  // Custom time filter for end time
  const filterEndTime = (time, startTime) => {
    const today = new Date();
    const currentTime = new Date();

    // If selected date is today and same as start date, restrict time
    if (
      startTime &&
      time.getDate() === today.getDate() &&
      time.getMonth() === today.getMonth() &&
      time.getFullYear() === today.getFullYear() &&
      startTime.getDate() === today.getDate()
    ) {
      // Ensure end time is after start time but before or equal to current time
      return time >= startTime && time <= currentTime;
    }

    // If start time is from a different date, allow all times for end time
    if (startTime && time.getDate() !== startTime.getDate()) {
      return true;
    }

    // For other scenarios, restrict to current time
    return time <= currentTime;
  };

  const handleInputChangeDistrict = (selectedDistrict, index) => {
    const filteredLocations = locationData.filter(
      (location) => location.district === selectedDistrict
    );
    filteredLocations.sort((a, b) => a.name.localeCompare(b.name));
    const updatedFlights = [...flights];
    updatedFlights[index]["locations"] = filteredLocations;
    setFlights(updatedFlights);
  };

  const handleFileChange = async (index, file) => {
    if (!file) return;

    const updatedFlights = [...flights];
    updatedFlights[index].file = file;
    updatedFlights[index].uploadProgress = 0;
    updatedFlights[index].uploadComplete = false;
    updatedFlights[index].isUploading = true;
    updatedFlights[index].estimatedTime = "";
    setFlights(updatedFlights);

    try {
      const { data: multipartUpload } = await getMultipartUploadId(file.name);

      const PART_SIZE = calculatePartSize(file.size);
      const totalParts = Math.ceil(file.size / PART_SIZE);
      var parts = [];

      const uploadPartsSequentially = async () => {
        // const presignedUrls = await Promise.all(
        //   Array.from({ length: totalParts }, (_, i) => {
        //     const partNumber = i + 1;
        //     return getPresignedUrl(
        //       multipartUpload.uploadId,
        //       file.name,
        //       partNumber
        //     );
        //   })
        // );

        const presignedUrls = await getPresignedUrl(
          multipartUpload.uploadId,
          file.name,
          totalParts
        );

        for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
          const start = (partNumber - 1) * PART_SIZE;
          const end = Math.min(start + PART_SIZE, file.size);
          const partSize = end - start;

          const signedUrl = presignedUrls[partNumber - 1];

          const partData = await uploadFileToS3(
            signedUrl,
            file,
            partNumber,
            totalParts,
            PART_SIZE,
            (progressInfo) => {
              const newFlights = [...updatedFlights];

              const overallProgress =
                (progressInfo.chunkIndex / totalParts) * 100;

              newFlights[index].uploadProgress = Math.min(overallProgress, 100);

              if (progressInfo.speed) {
                const remainingParts = totalParts - progressInfo.chunkIndex;
                const minutes = Math.floor(remainingParts * 0.5);
                const seconds = Math.round((remainingParts * 0.5) % 60);
                newFlights[
                  index
                ].estimatedTime = `${minutes} min ${seconds} sec`;
              }

              setFlights(newFlights);
            }
          );

          parts.push({
            ETag: partData.etag,
            PartNumber: partNumber,
          });

          console.log(`Uploaded part ${partNumber} successfully`);
          console.log(
            `- Part Size: ${(partSize / (1024 * 1024)).toFixed(2)} MB`
          );
        }

        return parts;
      };

      // for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
      //   const start = (partNumber - 1) * PART_SIZE;
      //   const end = Math.min(start + PART_SIZE, file.size);
      //   const partSize = end - start;

      //   const signedUrl = await getPresignedUrl(
      //     multipartUpload.uploadId,
      //     file.name,
      //     partNumber
      //   );

      //   const partData = await uploadFileToS3(
      //     signedUrl.data?.url,
      //     file,
      //     partNumber,
      //     totalParts,
      //     PART_SIZE,
      //     (progressInfo) => {
      //       const newFlights = [...updatedFlights];

      //       // Calculate overall progress based on chunk uploads
      //       const overallProgress =
      //         (progressInfo.chunkIndex / totalParts) * 100;

      //       newFlights[index].uploadProgress = Math.min(overallProgress, 100);

      //       if (progressInfo.speed) {
      //         const remainingParts = totalParts - progressInfo.chunkIndex;
      //         const minutes = Math.floor(remainingParts * 0.5); // Estimate 0.5 sec per part
      //         const seconds = Math.round((remainingParts * 0.5) % 60);
      //         newFlights[index].estimatedTime = `${minutes} min ${seconds} sec`;
      //       }

      //       setFlights(newFlights);
      //     }
      //   );

      //   // Log individual part details
      //   //  console.log(`Part ${partNumber} Details:`);
      //   //  console.log(
      //   //    `- Part Size: ${(partSize / (1024 * 1024)).toFixed(2)} MB`
      //   //  );
      //   //  console.log(`- Upload Speed: ${partData.speed}`);
      //   //  console.log(`- Completed At: ${partData.completedAt}`);

      //   //  // Special log for last part
      //   //  if (partNumber === totalParts) {
      //   //    console.log(`🔍 LAST PART DETAILS:`);
      //   //    console.log(
      //   //      `- Last Part Size: ${(partSize / (1024 * 1024)).toFixed(2)} MB`
      //   //    );
      //   //    console.log(
      //   //      `- Is Last Part Smaller Than 5 MB: ${
      //   //        partSize < 5 * 1024 * 1024
      //   //      }`
      //   //    );
      //   //  }

      //   parts.push({
      //     ETag: partData.etag,
      //     PartNumber: partNumber,
      //   });
      // }

      await uploadPartsSequentially();

      await completeMultipartUpload(file.name, multipartUpload.uploadId, parts);

      updatedFlights[index].uploadComplete = true;
      updatedFlights[index].isUploading = false;
      updatedFlights[index].uploadProgress = 100;
      setFlights(updatedFlights);
    } catch (error) {
      updatedFlights[index].isUploading = false;
      updatedFlights[index].uploadProgress = 0;
      setFlights(updatedFlights);
      alert("File upload failed. Please try again.");
    }
  };

  const uploadFileToS3 = (
    presignedUrl,
    file,
    chunkIndex,
    totalParts,
    partSize,
    progressCallback
  ) => {
    return new Promise(async (resolve, reject) => {
      const startTime = Date.now();
      const defaultOptions = {
        timeout: 300000,
        retryCount: 3,
        retryDelay: 10000,
      };
      const config = { ...defaultOptions };
      let currentRetry = 0;
      const start = (chunkIndex - 1) * partSize;
      const end = Math.min(start + partSize, file.size);
      const chunk = file.slice(start, end);

      const attemptUpload = async () => {
        try {
          const controller = new AbortController();
          const timeoutId = setTimeout(
            () => controller.abort(),
            config.timeout
          );

          const response = await fetch(presignedUrl, {
            method: "PUT",
            body: chunk,
            headers: {
              "Content-Type": file.type || "application/octet-stream",
              "Content-Length": chunk.size.toString(),
              "Access-Control-Expose-Headers": "ETag",
            },
            signal: controller.signal,
          });

          clearTimeout(timeoutId);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          // Calculate upload stats
          const elapsedTime = Date.now() - startTime;
          const speed = chunk.size / (elapsedTime / 1000);
          const readableSpeed =
            speed < 1024
              ? `${speed.toFixed(2)} B/s`
              : speed < 1024 * 1024
              ? `${(speed / 1024).toFixed(2)} KB/s`
              : `${(speed / (1024 * 1024)).toFixed(2)} MB/s`;

          // Notify progress
          if (progressCallback) {
            progressCallback({
              chunkIndex,
              progress: ((chunkIndex / totalParts) * 100).toFixed(2),
              speed: readableSpeed,
              completedAt: new Date().toISOString(),
            });
          }

          return {
            chunkIndex,
            etag: response.headers.get("ETag"),
            totalBytes: file.size,
            chunkBytes: chunk.size,
            speed: readableSpeed,
            completedAt: new Date().toISOString(),
          };
        } catch (error) {
          // Retry logic (similar to previous implementation)
          if (currentRetry < config.retryCount) {
            currentRetry++;
            console.warn(`Upload attempt ${currentRetry} failed. Retrying...`);
            await new Promise((resolve) =>
              setTimeout(resolve, config.retryDelay)
            );
            return attemptUpload();
          }
          throw error;
        }
      };

      try {
        const result = await attemptUpload();
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedFlights = [...flights];
    updatedFlights[index][field] = value;

    if (field === "startTime") {
      updatedFlights[index]["endTime"] = null;
    }

    setFlights(updatedFlights);
  };

  const removeFlight = (index) => {
    const updatedFlights = flights.filter((_, i) => i !== index);
    setFlights(updatedFlights);
  };

  return (
    <div
      key={index}
      className="bg-white shadow-lg rounded-xl p-6 space-y-6 transition-all duration-300 hover:shadow-xl"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-700">
          {`Surveillance ${index + 1}`}
        </h2>
        {index !== 0 && (
          <button
            onClick={() => removeFlight(index)}
            className="text-red-500 hover:text-red-700 transition-colors duration-300 p-1 rounded-full hover:bg-red-100"
          >
            <X className="w-6 h-6" />
          </button>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="w-full flex gap-4">
          <div className="w-1/2">
            <label className="block text-sm text-start font-medium text-gray-700 mb-1">
              Districts
            </label>
            <select
              value={flight.district}
              onChange={(e) => {
                handleInputChange(index, "district", e.target.value);
                handleInputChangeDistrict(e.target.value, index);
              }}
              className="w-full px-2 py-3 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out appearance-none"
            >
              <option value="" disabled>
                Select district
              </option>
              {districts?.map((district, locIndex) => (
                <option key={locIndex} value={district.district}>
                  {district.district}
                </option>
              ))}
            </select>
          </div>
          <div className="w-1/2">
            <label className="block text-sm text-start font-medium text-gray-700 mb-1">
              Location
            </label>
            <select
              value={flight.location}
              onChange={(e) =>
                handleInputChange(index, "location", e.target.value)
              }
              className="w-full px-2 py-3 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out appearance-none"
            >
              <option value="" disabled>
                Select location
              </option>
              {flight.locations?.map((location, locIndex) => (
                <option key={locIndex} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full flex gap-4">
          <div>
            <label className="block text-sm font-medium text-start text-gray-700 mb-1">
              Start Time
            </label>
            <CustomeTimePicker
              value={flight.startTime}
              onChange={(date) => handleInputChange(index, "startTime", date)}
              minDate={calculateMinStartDate()}
              maxDate={new Date()}
              filterTime={filterStartTime}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-start text-gray-700 mb-1">
              End Time
            </label>
            <CustomeTimePicker
              value={flight.endTime}
              onChange={(date) => handleInputChange(index, "endTime", date)}
              startTime={flight.startTime} // Pass start time for validation
              minDate={flight.startTime || calculateMinStartDate()}
              maxDate={new Date()}
              filterTime={(time) => filterEndTime(time, flight.startTime)}
              disabled={!flight.startTime}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        {/* Remarks section - 60% width */}
        <div className="w-[60%]">
          <label className="block text-sm font-medium text-start text-gray-700 mb-1">
            Remarks
          </label>
          <textarea
            placeholder="Optional"
            value={flight.remark}
            onChange={(e) => handleInputChange(index, "remark", e.target.value)}
            rows={3}
            maxLength={500}
            className="w-full h-[100px] px-4 py-2 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out appearance-none resize-none"
          ></textarea>
        </div>

        {/* File upload section - 40% width */}
        <div className="w-[40%]">
          <label className="block text-sm font-medium text-start text-gray-700 mb-1">
            Upload File
          </label>
          <input
            disabled={!flight.startTime || !flight.endTime || !flight.location}
            type="file"
            accept=".mp4"
            onChange={(e) => handleFileChange(index, e.target.files[0])}
            className="hidden"
            id={`file-upload-${index}`}
          />

          <label
            htmlFor={`file-upload-${index}`}
            className={`flex flex-col items-center justify-center w-full h-[100px] border-2 border-dashed border-gray-300 rounded-lg  hover:border-blue-500 transition-colors duration-200 bg-gray-50 ${
              !flight.startTime || !flight.endTime || !flight.location
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
            title={
              !flight.startTime || !flight.endTime || !flight.location
                ? "Please fill all fields before uploading"
                : ""
            }
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.currentTarget.classList.add("border-blue-500", "bg-blue-50");
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.currentTarget.classList.remove("border-blue-500", "bg-blue-50");
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.currentTarget.classList.remove("border-blue-500", "bg-blue-50");
              const file = e.dataTransfer.files[0];
              if (file && file.type === "video/mp4") {
                handleFileChange(index, file);
              }
            }}
          >
            <div className="flex flex-col items-center justify-center h-full">
              {flight.file ? (
                <div className="flex items-center">
                  <Upload className="text-gray-500 w-5 h-5 mr-2" />
                  <span className="text-blue-500 truncate max-w-[150px]">
                    {flight.file.name}
                  </span>
                  {flight.isUploading ? (
                    <Loader className="ml-2" />
                  ) : flight.uploadComplete ? (
                    <Check className="text-green-500 w-5 h-5 ml-2" />
                  ) : null}
                </div>
              ) : (
                <>
                  <Upload className="text-gray-500 w-6 h-6 mb-2" />
                  <p className="text-sm text-gray-500">
                    Drag & drop or click to upload MP4
                  </p>
                </>
              )}
            </div>
          </label>
        </div>
      </div>

      {flight.isUploading && (
        <div className="mt-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-600">
              Uploading: {flight.uploadProgress.toFixed(1)}%
            </span>
            {flight.estimatedTime && (
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="w-4 h-4 mr-2" />
                {flight.estimatedTime}
              </div>
            )}
          </div>
          <Progress value={flight.uploadProgress} />
          {flight.file && (
            <div className="mt-2 text-xs text-gray-500 flex justify-between">
              <span>{flight.file.name}</span>
              <span>{(flight.file.size / (1024 * 1024)).toFixed(2)} MB</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FlightForm;
