import * as React from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { useMediaQuery, createTheme, ThemeProvider } from "@mui/material";

// Extend dayjs with isSameOrBefore plugin
dayjs.extend(isSameOrBefore);

export default function CustomeTimePicker({
  value,
  onChange,
  minDate,
  maxDate,
  filterTime,
  startTime = null,
}) {
  const dayjsValue = value ? dayjs(value) : null;

  const handleChangeTime = (newValue) => {
    const standardDate = newValue ? newValue.toDate() : null;
    if (onChange) {
      onChange(standardDate);
    }
  };

  const calculateMinDate = () => {
    const minDate = dayjs("2024-11-01");
    return minDate;
  };

  const shouldDisableTime = (value, view) => {
    const selectedTime = dayjs(value);

    if (startTime) {
      const start = dayjs(startTime);

      if (selectedTime.isBefore(start, "day")) {
        return true;
      }

      if (view === "hours" && selectedTime.isSame(start, "day")) {
        return selectedTime.hour() < start.hour();
      }

      if (
        view === "hours" &&
        selectedTime.isSame(start, "day") &&
        start.hour() >= 12 &&
        selectedTime.hour() < 12
      ) {
        return true;
      }

      if (view === "minutes" && selectedTime.isSame(start, "hour")) {
        return selectedTime.minute() <= start.minute();
      }

      if (view === "seconds" && selectedTime.isSame(start, "minute")) {
        return selectedTime.second() <= start.second();
      }
    }

    return false;
  };

  // Use breakpoints for responsive styling
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          slotProps={{
            textField: {
              size: "large",
            },
            dialog: {
              sx: {
                "& .MuiPaper-root": {
                  padding: "1rem",
                  borderRadius: "8px",
                  [theme.breakpoints.down("sm")]: {
                    width: "90%",
                  },
                  [theme.breakpoints.up("md")]: {
                    width: "60%",
                  },
                },
              },
            },
          }}
          format="DD/MM/YYYY hh:mm A"
          value={dayjsValue}
          onChange={handleChangeTime}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          minDate={startTime ? dayjs(startTime) : calculateMinDate()}
          maxDate={maxDate ? dayjs(maxDate) : dayjs()}
          shouldDisableTime={shouldDisableTime}
          sx={{
            "& .MuiInputBase-input": {
              height: "80px",
            },
            backgroundColor: "white",
            color: "#4b5563",
            "&:focus": {
              outline: "none",
              ring: "2px solid rgba(59, 130, 246, 0.5)",
              borderColor: "#3b82f6",
            },
            transition: "all 150ms ease-in-out",
            appearance: "none",
            "& .MuiInputBase-root": {
              paddingTop: "-0.80rem",
              paddingBottom: "-0.80rem",
              height: "3.1rem",
            },
            "& .MuiPickersCalendarHeader-root": {
              fontSize: "1rem",
              [theme.breakpoints.down("sm")]: {
                fontSize: "0.8rem",
              },
              [theme.breakpoints.up("lg")]: {
                fontSize: "1.2rem",
              },
            },
            "& .MuiPickersClock-root": {
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                width: "80%",
              },
              [theme.breakpoints.up("md")]: {
                width: "60%",
              },
              [theme.breakpoints.up("lg")]: {
                width: "40%",
              },
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
