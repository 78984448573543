import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HttpStatusCode } from "axios";
import { createUser, updateUser } from "../api";
import { useAuth } from "../context/AuthContext";
import { Toaster, toast } from "sonner";

const AddUserModal = ({
  show,
  onClose,
  onAddUser,
  user,
  isEditSelf,
  setIsEditSelf,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("pilot");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { auth, logout, setAuthContext } = useAuth();

  const [modalUser, setModalUser] = useState(null);

  useEffect(() => {
    if (show && user) {
      // Populate form fields with user data for edit
      setModalUser(user);
      setEmail(user.email || "");
      setName(user.name || "");
      setRole(user.role || "pilot");
      setPhoneNumber(user.phoneNumber || "");
      setPassword("");
      setConfirmPassword("");
    } else if (show && !user) {
      // Reset form fields for adding a new user
      resetForm();
      setModalUser(null);
    }
  }, [show, user]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.name);
      setRole(user.role);
      setPhoneNumber(user.phoneNumber || "");
    }
  }, [user]);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // International phone number format
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }
      if (!validatePhoneNumber(phoneNumber)) {
        throw new Error("Invalid phone number");
      }

      const newUser = { name, email, password, phoneNumber, role };
      const response = await createUser(newUser);
      if (response?.status !== HttpStatusCode.Created) {
        throw new Error(response?.data?.message || "Failed to add user");
      } else {
        toast.success("User Created", {
          description: "User has been Created.",
          duration: 3000,
        });
      }
      await onAddUser(newUser);
      resetForm();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      if (password && password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }
      if (!validatePhoneNumber(phoneNumber)) {
        throw new Error("Invalid phone number");
      }

      const updatedUser = {
        name,
        phoneNumber,
        role,
        ...(password ? { password } : {}), // Include password only if provided
      };
      const response = await updateUser(user.id, updatedUser);
      if (response?.status !== HttpStatusCode.Ok) {
        throw new Error(response?.data?.message || "Failed to update user");
      }
      await onAddUser(response.data);
      resetForm();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setPhoneNumber("");
    setRole("pilot");
    setError("");
  };

  useEffect(() => {}, [show, user]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 p-4 overflow-y-auto">
      <Toaster
        richColors
        position="top-center"
        expand={false}
        className="z-[100]"
      />
      <div className="relative min-h-screen md:min-h-0 w-full flex items-center justify-center py-6">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-auto transform transition-all">
          <div className="max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 rounded-t-lg">
              <div className="flex justify-between items-center">
                <h2 className="text-xl sm:text-2xl font-extrabold text-gray-800 flex items-center">
                  {user ? "Edit" : "Add"} {isEditSelf ? "Profile" : "User"}
                </h2>
                <button
                  className="text-gray-600 hover:text-red-600 transition duration-200 p-2"
                  onClick={onClose}
                >
                  X
                </button>
              </div>
            </div>

            <div className="px-6 py-4">
              {error &&
                error.split(",").map((err, index) => (
                  <div key={index} className="text-red-600 mb-4">
                    {err}
                  </div>
                ))}

              <form
                onSubmit={user ? handleEdit : handleSubmit}
                className="space-y-4"
              >
                <div>
                  <label className="block text-gray-700 font-medium mb-1 text-left">
                    Name:
                  </label>
                  <input
                    placeholder="John Doe"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-700 font-medium mb-1 text-left">
                    Email:
                  </label>
                  <input
                    disabled={user?.email}
                    placeholder="john.doe@gmail.com"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 disabled:bg-gray-100"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-700 font-medium mb-1 text-left">
                    Phone Number:
                  </label>
                  <input
                    maxLength={10}
                    placeholder="+1234567890"
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-700 font-medium mb-1 text-left">
                    Password:
                  </label>
                  <input
                    placeholder="*********"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    required={!user}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 font-medium mb-1 text-left">
                    Confirm Password:
                  </label>
                  <input
                    placeholder="*********"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    required={!user}
                  />
                </div>

                {!isEditSelf && (
                  <div>
                    <label className="block text-gray-700 font-medium mb-1 text-left">
                      Role:
                    </label>
                    <select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                      required
                    >
                      <option value="pilot">Pilot</option>
                      <option value="viewer">Viewer</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                )}

                <div className="flex justify-end pt-4 border-t border-gray-200">
                  <button
                    type="button"
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg mr-2 transition duration-200 hover:bg-gray-400"
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-200 hover:bg-blue-500 ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={loading}
                  >
                    {user
                      ? loading
                        ? "Updating..."
                        : "Update"
                      : loading
                      ? "Creating..."
                      : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  isEditSelf: PropTypes.bool,
  setIsEditSelf: PropTypes.func,
};

export default AddUserModal;
