import React, { useState, useEffect } from "react";
import {
  Search,
  ChevronLeft,
  ChevronRight,
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from "lucide-react";
import { deleteUser, getUsersList } from "../api";
import { HttpStatusCode } from "axios";
import AddUserModal from "../components/AddUserModal";
import DeleteUserModal from "../components/DeleteUserModal";
import { Toaster, toast } from "sonner";
import { useAuth } from "../context/AuthContext"; // Assuming you have an auth context

const Users = () => {
  const [usersData, setUsersData] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
   const [shouldFetch, setShouldFetch] = useState(true); 

  const { auth, loading, setAuthContext } = useAuth(); // Get the logged-in user from auth context
  const usersPerPage = 7;

  const fetchUsers = async () => {
    const response = await getUsersList({
      limit: usersPerPage,
      page: currentPage,
      sortBy: "createdAt:desc",
      ...(searchTerm ? { email: searchTerm } : {}),
    });

    if (response?.status === HttpStatusCode.Ok) {
      // Filter out the current user and keep only users with 'admin' role
       setUsersData(response.data);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, searchTerm);
  }, [currentPage]);

   useEffect(() => {
     if (shouldFetch) {
       fetchUsers();
       setShouldFetch(false); // Reset after fetching
     }
   }, [shouldFetch]);

  useEffect(() => {
    const searchTimeOut = setTimeout(() => {
      fetchUsers(1, searchTerm);
    }, 1000);
    return () => {
      clearTimeout(searchTimeOut);
    };
  }, [searchTerm]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= usersData.totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleAddUser = (newUser) => {
    
    setUsersData((prevData) => ({
      ...prevData,
      results: [newUser, ...prevData.results],
      totalResults: prevData.totalResults + 1,
      // Recalculate total pages
      totalPages: Math.ceil((prevData.totalResults + 1) / usersPerPage),
    }));
     setShouldFetch(true);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setCurrentUser(null); // Reset currentUser when closing the modal
    setShowModal(false);
  };

   const handleOpenAddUserModal = () => {
     // Explicitly reset currentUser to null when opening Add User modal
     setCurrentUser(null);
     setShowModal(true);
   };

  const handleEditUser = (updatedUser) => {
    setUsersData((prevData) => {
      const updatedResults = prevData.results.map((user) =>
        user.id === updatedUser.id ? updatedUser : user
      );

      return { ...prevData, results: updatedResults };
    });

    setCurrentUser(null);
    setShowModal(false);

    // Add a success toast message
    toast.success("User updated successfully", {
      description: `${updatedUser.name} has been updated.`,
      duration: 3000,
    });
  };

 const handleConfirmDelete = async () => {
   if (!currentUser?.id) return;

   const response = await deleteUser(currentUser.id);
   if (response.status === HttpStatusCode.NoContent) {
     setUsersData((prevData) => {
       const updatedUsers = prevData.results.filter(
         (user) => user.id !== currentUser.id
       );

       const newTotalResults = prevData.totalResults - 1;
       const newTotalPages = Math.ceil(newTotalResults / usersPerPage);

       // Adjust pagination to ensure no empty pages
       const newCurrentPage =
         updatedUsers.length === 0 && currentPage > 1
           ? currentPage - 1
           : currentPage;

       return {
         ...prevData,
         results: updatedUsers,
         totalResults: newTotalResults,
         totalPages: newTotalPages,
       };
     });

     setCurrentPage((prevPage) =>
       usersData.results.length === 1 && prevPage > 1 ? prevPage - 1 : prevPage
     );

     setCurrentUser(null);
     setDeleteModalVisible(false);

     // Refetch to adjust the displayed data after deletion
     setShouldFetch(true);
   }
 };

  const getPaginationInfo = () => {
    if (!usersData.totalResults) {
      return {
        start: 0,
        end: 0,
        total: 0,
      };
    }

    const start = (currentPage - 1) * usersPerPage + 1;
    const end = Math.min(currentPage * usersPerPage, usersData.totalResults);

    return {
      start,
      end,
      total: usersData.totalResults,
    };
  };
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    return nameParts.length > 1
      ? nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase()
      : nameParts[0][0].toUpperCase();
  };

  const getAvatarColor = (name) => {
    const colors = [
      "bg-red-500",
      "bg-blue-500",
      "bg-green-500",
      "bg-yellow-500",
      "bg-purple-500",
      "bg-indigo-500",
    ];
    return colors[name.length % colors.length];
  };

  return (
    <div className="  flex-col bg-gray-100">
      <Toaster
        richColors
        position="top-center"
        expand={false}
        className="z-[100]"
      />

      <div className="flex-grow flex flex-col overflow-hidden pt-5">
        {/* Header */}
        <div className="bg-white shadow-md rounded-xl p-6 mb-4">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div>
              <h5 className="text-xl font-semibold text-gray-900">
                User Management
              </h5>
            </div>
            <div className="flex flex-col sm:flex-row gap-2">
              <div className="relative">
                <input
                  type="text"
                  className="w-full sm:w-64 px-3 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <button
                className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                onClick={() => {
                  setShowModal(true);
                   handleOpenAddUserModal();
                }
                 }
              >
                <PlusIcon className="h-5 w-5" />
                Add User
              </button>
            </div>
          </div>
        </div>

        {/* User table */}
        <div className="flex-grow overflow-auto bg-white shadow-md rounded-xl">
          <table className="w-full text-left">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-4 font-semibold text-gray-600">Name</th>
                <th className="px-4 py-4 font-semibold text-gray-600">Email</th>
                <th className="px-4 py-4 font-semibold text-gray-600">Role</th>
                <th className="px-4 py-4 font-semibold text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {usersData.totalResults ? (
                usersData.results?.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b border-gray-100 hover:bg-gray-50"
                  >
                    <td className="px-4 py-4">
                      <div className="flex items-center gap-3">
                        <div
                          className={`w-9 h-9 rounded-full flex items-center justify-center text-white text-xs ${getAvatarColor(
                            user.name
                          )}`}
                        >
                          {getInitials(user.name)}
                        </div>
                        <span className="font-bold">{user.name}</span>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-gray-600">{user.email}</td>
                    <td className="px-4 py-4">
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-medium capitalize ${
                          user.role === "admin"
                            ? "bg-red-100 text-red-800"
                            : user.role === "pilot"
                            ? "bg-blue-100 text-blue-800"
                            : "bg-green-100 text-green-800"
                        }`}
                      >
                        {user.role}
                      </span>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex gap-2">
                        <button
                          onClick={() => {
                            setCurrentUser(user);
                            setShowModal(true);
                          }}
                          className="p-2 rounded-md text-gray-600 hover:bg-gray-100 transition-colors"
                        >
                          <PencilIcon className="w-5 h-5" />
                        </button>
                        <button
                          disabled={
                            // Disable the button for the logged-in admin (self-admin), and not for others
                            user?.id === auth.user?.id && user?.role === "admin" // Disable only for the logged-in admin
                              ? true // Disable button for logged-in admin
                              : false // Enable button for others (including non-logged-in admins, pilots, viewers)
                          }
                          onClick={() => {
                            setCurrentUser(user);
                            setDeleteModalVisible(true);
                          }}
                          className={`p-2 rounded-md text-red-600 hover:bg-red-50 transition-colors ${
                            // Add styles based on the conditions
                            user?.id === auth.user?.id && user?.role === "admin" // Self-admin
                              ? "cursor-not-allowed opacity-50" // Style for self-admin (disabled)
                              : "cursor-pointer" // Style for others (enabled)
                          }`}
                        >
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="px-4 py-4 text-center text-gray-500"
                    colSpan="4"
                  >
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="bg-white p-4 mt-4 rounded-xl shadow-md flex items-center justify-between">
          <span className="text-sm text-gray-500">
            Showing {indexOfFirstUser + (usersData.totalResults ? 1 : 0)}-
            {indexOfLastUser > usersData.totalResults
              ? usersData.totalResults
              : indexOfLastUser}{" "}
            of {usersData.totalResults}
          </span>
          <div className="space-x-2 flex items-center">
            <button
              className={`px-3 py-1 rounded-md text-sm ${
                currentPage === 1
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            {[...Array(usersData.totalPages)].map((_, index) => (
              <button
                key={index + 1}
                className={`px-3 py-1 rounded-md text-sm ${
                  currentPage === index + 1
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`px-3 py-1 rounded-md text-sm ${
                currentPage === usersData.totalPages
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === usersData.totalPages}
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      <DeleteUserModal
        show={isDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        onConfirm={handleConfirmDelete}
        itemName={currentUser?.name}
      />
      <AddUserModal
        show={showModal}
        onClose={handleCloseModal}
        onAddUser={currentUser ? handleEditUser : handleAddUser}
        user={currentUser}
      />
    </div>
  );
};

export default Users;
